import React, { Suspense, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import { TeamOutlined, ShoppingOutlined, InteractionOutlined, FilePptOutlined, UserOutlined, SettingOutlined, ClusterOutlined } from '@ant-design/icons';

import BaseLayout from '@src/layouts/BaseLayout';
const ClientManagement = lazy(() => import('@pages/ClientManagement'))
const ProductLibraryManagement = lazy(() => import('@pages/ProductLibraryManagement'))
const PromptManagement = lazy(() => import('@pages/PromptManagement'))
const FunctionCall = lazy(() => import('@pages/FunctionCall'))
const FAQManagement = lazy(() => import('@pages/FAQManagement'))
const SDKManagement = lazy(() => import('@pages/SDKManagement'))
const AccountManagement = lazy(() => import('@pages/AccountManagement'))
const PriceManagement = lazy(() => import('@pages/PriceManagement'))
const LogisticsManagement = lazy(() => import('@pages/LogisticsManagement'))
const FormatConversionTool = lazy(() => import('@pages/FormatConversionTool'))
const AutoTest = lazy(() => import('@pages/AutoTest'))
const Setting = lazy(() => import('@pages/Setting'))
const Login = lazy(() => import('@pages/Login'))
const Error = lazy(() => import('@pages/404'))

const Meishubao = lazy(() => import('@pages/Meishubao'))

const LazyComponent = (element: ReactNode): ReactNode => {
  return (
    <Suspense fallback={<div></div>}>
      { element }
    </Suspense>
  )
}

const routeList = [
  {
    path: '/',
    element: <Navigate to="/clientManagement" replace={true} />
  },
  {
    path: '/',
    meta: {
      auth: true,
      menu: true,
    },
    element: LazyComponent(<BaseLayout />),
    children: [
      {
        path: '/clientManagement',
        element: LazyComponent(<ClientManagement />),
        meta: {
          menu: true,
          icon: <TeamOutlined />,
          label: '客户管理',
        },
      },
      {
        path: '/productLibraryManagement',
        element: LazyComponent(<ProductLibraryManagement />),
        meta: {
          menu: true,
          icon: <ShoppingOutlined />,
          label: '产品库管理',
        },
      },
      {
        path: '/faqManagement',
        element: LazyComponent(<FAQManagement />),
        meta: {
          menu: true,
          icon: <FilePptOutlined />,
          label: 'FAQ管理',
        },
      },
      {
        path: '/priceManagement',
        element: LazyComponent(<PriceManagement />),
        meta: {
          menu: true,
          icon: <FilePptOutlined />,
          label: '价格规则管理',
        },
      },
      {
        path: '/logisticsManagement',
        element: LazyComponent(<LogisticsManagement />),
        meta: {
          menu: true,
          icon: <FilePptOutlined />,
          label: '物流规则管理',
        },
      },
      {
        path: '/promptManagement',
        element: LazyComponent(<PromptManagement />),
        meta: {
          menu: true,
          icon: <FilePptOutlined />,
          label: 'Prompt管理',
        },
      },
      {
        path: '/functionCall',
        element: LazyComponent(<FunctionCall />),
        meta: {
          menu: true,
          icon: <FilePptOutlined />,
          label: 'Function Call',
        },
      },
      {
        path: '/sdkManagement',
        element: LazyComponent(<SDKManagement />),
        meta: {
          menu: true,
          icon: <FilePptOutlined />,
          label: '聊天窗口SDK',
        },
      },
      {
        path: '/formatConversionTool',
        element: LazyComponent(<FormatConversionTool />),
        meta: {
          menu: true,
          icon: <InteractionOutlined />,
          label: '格式转换工具',
        },
      },
      {
        path: '/accountManagement',
        element: LazyComponent(<AccountManagement />),
        meta: {
          menu: true,
          icon: <UserOutlined />,
          label: '账号管理',
        },
      },
      {
        path: '/autoTest',
        element: LazyComponent(<AutoTest />),
        meta: {
          menu: true,
          icon: <ClusterOutlined />,
          label: '自动测试',
        },
      },
      {
        path: '/setting',
        element: LazyComponent(<Setting />),
        meta: {
          menu: true,
          icon: <SettingOutlined />,
          label: '设置',
        },
      },
    ]
  },
  {
    path: '/login',
    element: LazyComponent(<Login />)
  },
  {
    path: '/meishubao',
    element: LazyComponent(<Meishubao />)
  },
  {
    path: '/*',
    element: LazyComponent(<Error />)
  }
]

export default routeList;